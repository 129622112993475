<template>
  <div class="antd-layout-container">
    <div class="login-panel">
      <div>
        <h2 style="font-size: 28px">智慧水务管理系统</h2>
        <!-- <h2>{{ company }}</h2> -->
        <h2>平凉新安煤业有限责任公司</h2>
        <a-form :form="form" class="login-form" @submit="login">
          <p class="des">控制台登录</p>
          <a-form-item>
            <a-input
              size="large"
              v-decorator="[
                'userName',
                { rules: [{ required: true, message: '请输入账号!' }] },
              ]"
              placeholder="账号/邮箱"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入密码!' }] },
              ]"
              type="password"
              placeholder="密码"
              autocomplete
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-checkbox
              v-decorator="[
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]"
              >记住我</a-checkbox
            >
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-form-button"
              :loading="loading"
              >登录</a-button
            >
            <a class="login-form-forgot" href>忘记密码？</a>
            <a href>现在注册!</a>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="bg"></div>
    <div class="copyright">
      <p>请使用IE9.0以上或Chrome(谷歌)浏览器访问本系统</p>
      <p>Copyright ©2019 OnePower Corporation, All Rights Reserved</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >豫ICP备2021025725-1号</a
        >
      </p>
      <!-- <p>湖北爱特云信息技术有限公司</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      company: "",
      form: this.$form.createForm(this),
    };
  },
  created() {
    // this.getCompany();
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (
          !err &&
          values.userName != undefined &&
          values.password != undefined
        ) {
          this.loading = true;
          this.$http
            .post(this.$api.LOGIN, {
              username: values.userName,
              password: values.password,
            })
            .then((res) => {
              // console.log(res);
              if (res.code == 1) {
                this.$store.dispatch("Login", res.data);
                this.$store.dispatch("initRoutes");
                var path = this.$route.query.redirect;
                this.$router.replace({
                  path:
                    path == "/" || typeof path == "undefined"
                      ? "/dashboard"
                      : path,
                });

                // window.location.reload();
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
      return false;
    },
    getCompany() {
      this.$http.get("/common/company").then((res) => {
        if (res.code == 1) {
          this.company = res.data.name;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.antd-layout-container {
  background-image: url("../assets/images/loginbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  flex: 1;
}
.login-panel {
  border-radius: 4px;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-60%);
  width: 400px;
  z-index: 2;
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .brand {
    text-align: center;

    flex: 1;
  }
  h2 {
    color: #fff;
    text-shadow: 1px 2px 5px #616161;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  p.des {
    margin-top: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    text-align: left;
  }
}
.login-form {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  box-shadow: 10px 15px 0px rgba(0, 0, 0, 0.2);
  .ant-form-item {
    margin-bottom: 0;
    margin-top: 0px;
    .ant-input-affix-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .login-form-button {
      margin: 6px 0;
      width: 100%;
      height: 50px;
    }
  }
}
.copyright {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(5, 28, 104);
  text-align: center;
  p {
    margin: 0;
  }
}
.ant-btn-primary {
  background-color: #3b63e6;
  border-color: #3b63e6;
  &:hover,
  &:focus {
    background-color: #416dfd;
    border-color: #416dfd;
  }
}
</style>
